.zeynep {
  top: 0;
  bottom: 0;
  position: fixed;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1011;
  pointer-events: none;
  transform: translateX(-100%);
  -webkit-overflow-scrolling: touch;
}

.zeynep:not(.no-transition),
.zeynep .submenu:not(.no-transition) {
  /* if transitions are not disabled */
  transition: all 250ms;
}

.zeynep-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  position: fixed;
  z-index: 1010;
  background-color: rgba(0, 0, 0, .42);
}

.zeynep-opened .zeynep-overlay {
  display: block;
}

.zeynep.opened {
  pointer-events: auto;
  transform: translateX(0px);
}

.zeynep.submenu-opened {
  overflow: hidden;
}

.zeynep .submenu {
  top: 0;
  bottom: 0;
  min-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  pointer-events: none;
  -webkit-overflow-scrolling: touch;
}

.zeynep .submenu.opened {
  left: 0;
  pointer-events: auto;
}

.zeynep .submenu.opened:not(.current) {
  overflow: hidden;
}
